<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          block
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <FiltersAreaCombo :activeElements="activeItems" />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
            </CRow>
            <CRow>
              <CCol xl="3">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol xl="6">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="!showLoader" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <TableIncentivesNetwork
          :tableData="tableData"
          :productCodes="productCodes"
        > 
          <span slot="area">{{ selectedArea }}{{ selectedArea && ' - ' }}</span>
          <span slot="period">{{ selectedPeriod }}</span>
          <span slot="fiscalYear">{{ selectedFY }}</span>
        </TableIncentivesNetwork>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Api from "@/services/Api";
import currentFY from "@/services/CurrentFY";
import defaultAreaParam from "@/helpers/defaultAreaParam";
import calculatePeriod from "@/helpers/calculatePeriod";
import langStr from "@/utils/langStr";
import formatNum from "@/utils/formatNum";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

const FiltersAreaCombo = () =>
  import(
    /* webpackChunkName: "filtersAreaCombo" */ "@/components/organisms/FiltersAreaCombo"
  );
const FilterFY = () =>
  import(/* webpackChunkName: "filter-fiscal-year" */ "@/components/molecules/FilterFY");
const FilterMonthsRange = () =>
  import(
    /* webpackChunkName: "filterMonthsRange" */ "@/components/molecules/FilterMonthsRange"
  );
const Loader = () =>
  import(
    /* webpackChunkName: "table-incentives-network" */ "@/components/atoms/Loader"
  );
const TableIncentivesNetwork = () =>
  import(
    /* webpackChunkName: "table-incentives-network" */ "@/components/organisms/TableIncentivesNetwork"
  );

export default {
  name: "NetworkIncentiveEstimation",
  components: {
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    Loader,
    TableIncentivesNetwork,
  },
  data() {
    return {
      filtersList: null,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      cardCollapse: true,
      areaError: false,
      user: null,
      lang: null,
      messages: null,
      productCodes: ["BV", "BV85", "T05", "T03", "BPC", "ACC"],
      showLoader: true,
      tableId: "incentiveNetwork",
      tableData: [],
      intermediateData: [],
      selectedArea: null,
    };
  },
  computed: {
    languageId() {
      return langStr.getId(this.lang);
    },
    fields() {
      let fieldsArray = [
        {
          key: "rowName",
          label: "",
        },
      ];
      this.productCodes.forEach((item) => {
        fieldsArray.push({
          key: item,
          label: this.messages.products.byCodeList[item].toUpperCase(),
        });
      });
      return fieldsArray;
    },
  },
  methods: {
    getDefaultParams() {
      currentFY().then((currentFY) => {
        const defaultArea = defaultAreaParam(this.user);
        const period = calculatePeriod(currentFY);
        const area = {
          countryCode: "",
          regionalCode: "",
          aspm: "",
          concessionCode: "",
        };
        area[defaultArea.key] = defaultArea.value;
        const defaults = {
          ...area,
          productCodes: this.productCodes,
          fiscalYear: period.year,
          sinceMonth: period.start,
          upToMonth: period.end,
          languageId: this.languageId,
        };
        console.log("Defaults", defaults);
        this.getTableData(defaults).then((data) => this.processTableData(data));
      });
    },
    submitFilterValues(event) {
      event.preventDefault();

      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
      } else {
        this.areaError = false;
        const params = {
          ...this.$store.getters.getFiltersAreaComboParams,
          productCodes: this.productCodes,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
          languageId: this.languageId,
        };

        console.log("Params", params);
        this.getTableData(params).then((data) => this.processTableData(data));
      }
    },
    async getTableData(params) {
      this.showLoader = true;
      this.setTableTitle(params);
      const results = await Api.sendPost("commercial/incentiveRed/v2", params);
      return results.data.data;
    },
    processTableData(rawData) {
      this.intermediateData = [];
      let data = [];
      const objectName = Object.keys(rawData)[0];
      const initialObject = rawData[objectName];
      this.iterateObject(objectName, initialObject);
      if (this.intermediateData.length === 1) {
        data = this.intermediateData[0];
      } else {
        data = this.intermediateData.reduce((a, b) => {
          return a !== undefined ? a.concat(b) : [];
        });
      }
      this.tableData = data;
      this.showLoader = false;
    },
    iterateObject(objectName, object) {
      const result = [];
      // console.log("OBJECT NAME", objectName, "OBJECT", object);
      if (objectName === "concessionIncentiveRedContent") {
        result.push(this.getConcessionRow(object));
      } else {
        for (let prop in object) {
          if (object[prop] !== null) {
            if (prop !== "total") {
              if (typeof object[prop] === "object") {
                const objectName = prop;
                if (Object.keys(object[prop][0]).includes("total")) {
                  object[prop].forEach((item) => {
                    this.iterateObject(objectName, item);
                  });
                } else {
                  if (prop === "concessionIncentiveRedContentList") {
                    object[prop].forEach((item) => {
                      result.push(this.getConcessionRow(item));
                    });
                  } else {
                    return;
                  }
                }
              }
            }
          }
        }
        for (let prop in object) {
          if (prop === "total") {
            result.push(this.getTotalRow(objectName, object, object.total));
          }
        }
      }

      if (result.length > 0) {
        this.intermediateData.push(result);
      }
    },
    getTotalRow(prop, object, valuesArray) {
      const titleStrByProp = {
        countryIncentiveRedContent: this.messages.common.national.toUpperCase(),
        regionalIncentiveRedContent:
          this.messages.common.regional.toUpperCase(),
        regionalIncentiveRedContentList:
          this.messages.common.regional.toUpperCase(),
        aspmIncentiveRedContent: "ASPM",
        aspmIncentiveRedContentList: "ASPM",
      };
      const codeStrByProp = {
        countryIncentiveRedContent: "countryCode",
        regionalIncentiveRedContent: "regionalCode",
        regionalIncentiveRedContentList: "regionalCode",
        aspmIncentiveRedContent: "aspm",
        aspmIncentiveRedContentList: "aspm",
      };
      // const type = codeStrByProp[prop];
      const typeValue = object[codeStrByProp[prop]];
      const typeTitle = titleStrByProp[prop];
      const array = valuesArray;

      console.log("typeTitle", typeTitle, "typeValue", typeValue);

      const areaTitle =
        typeTitle === "ASPM"
          ? typeValue
          : this.messages.areaByCode[typeValue] !== undefined
          ? this.messages.areaByCode[typeValue].toUpperCase()
          : "";
      const row = {
        _classes: "dark-gray",
        rowName: `TOTAL ${typeTitle} ${areaTitle}`,
      };

      array.forEach((product) => {
        row[product["productCode"]] = `${formatNum.get(product.incentive, 2)}€`;
      });
      return row;
    },
    getConcessionRow(concession) {
      // console.log(concession);
      const description = `${concession.concessionCode} ${concession.businessName}`;
      const row = {
        _classes: "focus",
        rowName: description,
      };
      concession.productContentList.forEach((product) => {
        row[product["productCode"]] = `${formatNum.get(product.incentive, 2)}€`;
      });
      return row;
    },
    setTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      this.selectedFY = formatFiscalYear(params.fiscalYear);
      const sinceMonth = this.messages.filters.months[params.sinceMonth];
      const upToMonth = this.messages.filters.months[params.upToMonth]
      this.selectedPeriod = sinceMonth === upToMonth ? sinceMonth : `de ${sinceMonth} a ${upToMonth}`;
    },
  },
  created() {
    this.user = this.$store.getters.getCurrentUser;
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    this.getDefaultParams();
  },
};
</script>
